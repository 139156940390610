import React, { useState } from 'react';
import { Card, Button } from 'antd';
import { useSpring, animated } from 'react-spring';
import Lottie from 'react-lottie';
import animationData from '../../assets/panel-help/woman-help.json';
import { FaLinkedin, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import { ModalHelp } from '../Modals/ModalHelp';

const PortalAjuda: React.FC<any> = () => {
  const [IsModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const cardAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  });

  const buttonAnimation = useSpring({
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' },
    delay: 300,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <animated.div style={cardAnimation}>
      <Card
        style={{
          cursor: 'pointer',
          padding: '5px',
          background: '#252f64',
          margin: '15px',
          marginTop: '80px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h3 style={{ color: '#fff', margin: 0, marginLeft: '10px' }}>Canais de Atendimento</h3>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Lottie options={defaultOptions} height={100} width={100} />
        </div>
        <p style={{ color: '#fff', marginBottom: '15px', fontSize: 12 }}>Explore nossos canais de atendimento para obter suporte dedicado e eficiente.</p>
        <animated.div style={{ display: 'flex', flexDirection: 'column', ...buttonAnimation }}>
          <Button
            onClick={showModal}
            style={{
              background: '#3f4a9f',
              color: '#fff',
              borderColor: '#3f4a9f',
              marginBottom: '10px',
            }}
          >
            Fale Conosco
          </Button>
          <Button href="https://finlytech.atlassian.net/servicedesk/customer/portal/3" target="_blank" style={{ background: '#3f4a9f', color: '#fff', borderColor: '#3f4a9f' }}>
            Portal de Ajuda
          </Button>
        </animated.div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <a href="https://www.linkedin.com/company/finly-tech/mycompany/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', margin: '0 10px', fontSize: '24px' }}>
            <FaLinkedin />
          </a>
          <a href="https://wa.me/5531997908777" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', margin: '0 10px', fontSize: '24px' }}>
            <FaWhatsapp />
          </a>
          <a href="https://www.instagram.com/finlytech/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', margin: '0 10px', fontSize: '24px' }}>
            <FaInstagram />
          </a>
        </div>
        <ModalHelp isOpen={IsModalVisible} onClose={handleModalClose} />
      </Card>
    </animated.div>
  );
};

export default PortalAjuda;
