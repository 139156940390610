import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Col, Row, Skeleton, message } from 'antd';
import { AlertOutlined, DollarCircleOutlined, FallOutlined, FieldNumberOutlined, LikeOutlined, ReloadOutlined } from '@ant-design/icons';
import { Container } from '../../styles/GlobalStyles';
import TableComponent from '../../components/TablePagination';
import { StatCard } from '../../components/Charts/StatCard';
import { columnsPagamento } from './interfaces/columnsPagamento';
import { renderPagamento } from './interfaces/pagamentoDetails';
import { fetchPagamento, fetchPagamentoPdf, fetchPagamentoXls } from '../../store/states/pagamento/actions';
import CollapseFilter from '../../components/CollapseFilter';
import AnimatedBanner from '../../components/AnimatedBanner';
import { DropDownContent } from '../../components/DropDownContent';
import { mapFiltersPagamentoForApi } from '../../utils/validateFilter';
import MessageHeader from '../../components/MessageHeader';
import { textPagamento } from './interfaces';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';

function PagamentoScreen() {
  const dispatch = useDispatch();
  const pagamentoLoadingTotals = useSelector((state: RootState) => state.pagamento.loadingTotals);
  const pagamentoLoading = useSelector((state: RootState) => state.pagamento.loading);
  const pagamentoLoadingExport = useSelector((state: RootState) => state.pagamento.loadingPagamentoExport);
  const pagamentoTotals = useSelector((state: RootState) => state.pagamento.pagamentoTotals);
  const pagamentoChartData = useSelector((state: RootState) => state.pagamento.pagamentoChartData);
  const pagamentos = useSelector((state: RootState) => state.pagamento.data);
  const [showAcquirer, setShowAcquirer] = useState(() => {
    const persistedState = localStorage.getItem('showAcquirerPagamento');
    return persistedState ? JSON.parse(persistedState) : true;
  });
  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const bancos = useSelector((state: RootState) => state.banco.data);
  const adquirentes = useSelector((state: RootState) => state.adquirente.data);
  const bandeiras = useSelector((state: RootState) => state.bandeira.data);
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [selectedAdquirentes, setSelectedAdquirentes] = useState<string[]>([]);
  const [selectedBanco, setSelectedBanco] = useState(null);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [perPage] = useState(10);
  const [pagamentosTotal, setPagamentosTotal] = useState(0);
  const [totalLiquidoSubFiltro, setTotalLiquidoSubFiltro] = useState(0);
  const [totalBrutoSubFiltro, setTotalBrutoSubFiltro] = useState(0);
  const adquirentesAtivos = new Set(pagamentoChartData.map((data: any) => data.ADQUIRENTENOME));
  const adquirentesFiltradas = adquirentes ? adquirentes.filter((adquirente: any) => adquirentesAtivos.has(adquirente.adquirentenome)) : [];
  const bandeirasAtivas = new Set(pagamentoChartData.map((data: any) => data.BANDEIRANOME));
  const bandeirasFiltradas = bandeiras ? bandeiras.filter((bandeira: any) => bandeirasAtivas.has(bandeira.bandeiranome)) : [];
  const bancosAtivos = new Set(pagamentoChartData.map((data: any) => data.BANCONOME));
  const bancosFiltrados = bancos ? bancos.filter((banco: any) => bancosAtivos.has(banco.banconome)) : [];
  const estabelecimentos = new Set(pagamentoChartData.map((data: any) => data.ESTABELECIMENTONUMERO));
  const [resetTableFilters, setResetTableFilters] = useState(false);
  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    setPagamentosTotal(pagamentos.totalList);
    setTotalLiquidoSubFiltro(pagamentos.totalValorLiquido);
    setTotalBrutoSubFiltro(pagamentos.totalValorBruto);
  }, [pagamentos]);

  useEffect(() => {
    localStorage.setItem('showAcquirerPagamento', JSON.stringify(showAcquirer));
  }, [showAcquirer]);

  const handleExportPdf = () => {
    message.success('Gerando o relatório de pagamentos no formato .pdf, aguarde...');
    dispatch(
      fetchPagamentoPdf({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        filters: mapFiltersPagamentoForApi(filters),
      }),
    );
  };

  const handleExportXls = () => {
    message.success('Gerando o relatório de pagamentos no formato .xls, aguarde...');
    dispatch(
      fetchPagamentoXls({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        filters: mapFiltersPagamentoForApi(filters),
      }),
    );
  };

  const handleAdquirenteSelect = (adquirenteName: any) => {
    let newSelectedAdquirentes = [...selectedAdquirentes];
    if (newSelectedAdquirentes.includes(adquirenteName)) {
      newSelectedAdquirentes = newSelectedAdquirentes.filter(name => name !== adquirenteName);
    } else {
      newSelectedAdquirentes.push(adquirenteName);
    }
    setSelectedAdquirentes(newSelectedAdquirentes);
    const newFilters = mapFiltersPagamentoForApi({ ADQUIRENTENOME: newSelectedAdquirentes });
    setFilters(newFilters);
    fetchPagamentos(currentPage, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', newFilters);
    setActiveFilter(newSelectedAdquirentes.join(', '));
  };

  const handleBancoSelect = (bancoName: any) => {
    if (selectedBanco === bancoName) {
      setSelectedBanco(null);
      setActiveFilter(null);
      fetchPagamentos(1, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', mapFiltersPagamentoForApi({ BANCONOME: null }));
    } else {
      const newFilters = selectedBanco === bancoName ? {} : mapFiltersPagamentoForApi({ BANCONOME: bancoName });
      setFilters(newFilters);
      fetchPagamentos(currentPage, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', newFilters);
      setSelectedBanco(bancoName);
      setActiveFilter(bancoName);
      setSelectedAdquirentes([]);
    }
  };

  const fetchPagamentos = (page: number, pageSize: number, sortField: any, sortOrder: any, filters: any) => {
    const order = sortOrder !== undefined && sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const apiFilters = mapFiltersPagamentoForApi(filters);
    dispatch(
      fetchPagamento({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        page,
        pageSize,
        filters: apiFilters,
        sortField,
        sortOrder: order,
      }),
    );
  };

  const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'PAGAMENTODATAPAGAMENTO', sortOrder: string = 'DESC', filters: any) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchPagamentos(page, pageSize, sortField, sortOrder, filters);
  };

  const handleRefresh = () => {
    localStorage.removeItem('showAcquirerPagamento');
    setFilters({});
    setSelectedAdquirentes([]);
    setSelectedBanco(null);
    setActiveFilter(null);
    setCurrentPage(1);
    setShowAcquirer(true);
    setResetTableFilters(prev => !prev);
    fetchPagamentos(1, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', {});
    setTableKey(prevKey => prevKey + 1);
  };

  const updateFilters = (newFilters: any) => {
    setFilters(newFilters);
  };

  return (
    <AnimatedBanner>
      <Container>
        <>
          <MessageHeader textMessage={textPagamento} />
          <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
            <Col flex="5">
              <StatCard
                loadingData={pagamentoLoadingTotals}
                title="Bruto"
                value={(pagamentoTotals.pagamentoTotalBruto || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <div>
                <StatCard
                  loadingData={pagamentoLoadingTotals}
                  title="Líquido"
                  value={
                    pagamentoTotals && pagamentoTotals.pagamentoTotalLiquido
                      ? (pagamentoTotals.pagamentoTotalLiquido - (pagamentoTotals.pagamentoTotalAntecipado ? pagamentoTotals.pagamentoTotalAntecipado : 0)).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                      : 0
                  }
                  icon={<DollarCircleOutlined />}
                />
              </div>
            </Col>
            <Col flex="5">
              <div>
                <StatCard
                  loadingData={pagamentoLoadingTotals}
                  title="Antecipação"
                  value={
                    pagamentoTotals && pagamentoTotals.pagamentoTotalAntecipado
                      ? pagamentoTotals.pagamentoTotalAntecipado.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                      : 0
                  }
                  icon={<DollarCircleOutlined />}
                />
              </div>
            </Col>
            <Col flex="5">
              <StatCard loadingData={pagamentoLoadingTotals} title="Menor %" value={`${pagamentoTotals.pagamentoMenorTaxa || 0}%`} icon={<FallOutlined />} />
            </Col>
            <Col flex="5">
              <StatCard loadingData={pagamentoLoadingTotals} title="Registros" value={parseFloat(pagamentoTotals.pagamentoTotalRegistros || 0).toLocaleString('pt-BR')} icon={<FieldNumberOutlined />} />
            </Col>
            <Col flex="5">
              <StatCard loadingData={pagamentoLoadingTotals} title="Conciliado(s)" value={pagamentoTotals.pagamentosConciliados || 0} icon={<LikeOutlined />} />
            </Col>
            <Col flex="5">
              <StatCard loadingData={pagamentoLoadingTotals} title="Não conciliado(s)" value={pagamentoTotals.pagamentosNaoConciliados || 0} icon={<AlertOutlined />} />
            </Col>
          </Row>
          {pagamentoLoading ? (
            <Skeleton active />
          ) : (
            <>
              <CollapseFilter
                isCollapseOpen={isCollapseOpen}
                setIsCollapseOpen={setIsCollapseOpen}
                setShowAcquirer={setShowAcquirer}
                showAcquirer={showAcquirer}
                handleAdquirenteSelect={handleAdquirenteSelect}
                handleBancoSelect={handleBancoSelect}
                activeFilter={activeFilter}
                adquirentes={adquirentesFiltradas}
                bancos={bancosFiltrados}
                selectedAdquirentes={selectedAdquirentes}
                selectedBanco={selectedBanco}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 10, gap: 5 }}>
                {pagamentos.list &&
                  <ButtonRefreshList loading={pagamentoLoading} icon={<ReloadOutlined />} onClick={handleRefresh}>
                    Limpar Filtros
                  </ButtonRefreshList>}
                {pagamentos.list && pagamentos.list.length !== 0 &&
                  <DropDownContent loadingExport={pagamentoLoadingExport} transacoes={pagamentos} handleExportXls={handleExportXls} handleExportPdf={handleExportPdf} />}
              </div>
            </>
          )}
          <TableComponent
            key={tableKey}
            loadingData={pagamentoLoading}
            data={pagamentos.list && pagamentos.list.length > 0 ? pagamentos.list : []}
            columns={columnsPagamento(false, Array.from(adquirentesFiltradas), Array.from(bandeirasFiltradas), Array.from(estabelecimentos), selectedAdquirentes)}
            rowKeyId="PAGAMENTOID"
            rowRender={renderPagamento}
            perPage={perPage}
            currentPage={currentPage}
            totalPages={pagamentosTotal}
            onChangePage={handlePageChange}
            expand={true}
            externalFilters={filters}
            totalBrutoSubFiltro={totalBrutoSubFiltro}
            totalLiquidoSubFiltro={totalLiquidoSubFiltro}
            resetFilters={resetTableFilters}
            updateFilters={updateFilters}
          />
        </>
      </Container>
    </AnimatedBanner>
  );
}

export default PagamentoScreen;
