import React from 'react';
import { ButtonExportXlsPdfStyled } from './styled';

interface ButtonExportXlsPdfProps {
    onClick: () => void;
    icon: React.ReactNode;
    loading: boolean;
    disabled: boolean;
}

const ButtonExportXlsPdf: React.FC<ButtonExportXlsPdfProps> = ({ onClick, icon, loading, disabled }) => {
    return (
        <ButtonExportXlsPdfStyled onClick={onClick} icon={icon} loading={loading} disabled={disabled}>
            Exportar Relatório
        </ButtonExportXlsPdfStyled>
    );
};

export default ButtonExportXlsPdf;