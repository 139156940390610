import React, { useState } from 'react';
import { Card, Form, Modal, Input, Upload, Button, Tooltip, message } from 'antd';
import { ModalDisplay } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile, uploadUserPhoto } from '../../../store/states/usuario/actions';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import { RootState } from '../../../store/types';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalPerfil: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.usuario.profile);

  const onChange = ({ fileList: newFileList }: { fileList: any[] }) => {
    setFileList(newFileList);
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.warning('Você só pode fazer upload de arquivos JPG/PNG!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.warning('A imagem deve ser menor que 5MB!');
    }
    return isJpgOrPng && isLt5M;
  };

  const onUpload = () => {
    const file = fileList[0];
    dispatch(uploadUserPhoto(file));
    dispatch(fetchUserProfile());
    setFileList([]);
  };

  return (
    <ModalDisplay>
      <Modal visible={isOpen} onCancel={onClose} footer={null} width={650} centered>
        <Card bordered={false} style={{ borderRadius: '15px', boxShadow: '0 4px 12px rgba(0,0,0,0.2)' }}>
          <Form layout="vertical">
            <Form.Item label="Avatar">
              <div className="avatar-wrapper" style={{ marginBottom: 20 }}>
                {!user || user.usuariofoto === null || user.usuariofoto === '' ? (
                  <UserOutlined style={{ fontSize: '36px' }} />
                ) : (
                  <img src={user.usuariofoto} alt="Avatar do usuário" style={{ width: '90px', height: '90px', borderRadius: '50%' }} />
                )}
                <Upload
                  accept=".jpg,.jpeg,.png"
                  beforeUpload={beforeUpload}
                  onChange={onChange}
                  fileList={fileList}
                  style={{ marginTop: 10 }}
                >
                  <Tooltip title="Alterar foto">
                    <Button icon={<UploadOutlined />} shape="circle" style={{ marginLeft: 10 }} />
                  </Tooltip>
                </Upload>
              </div>
              <Button type="primary" onClick={onUpload} disabled={!fileList.length} style={{ marginTop: 10 }}>
                Upload
              </Button>
            </Form.Item>
            <Form.Item label="Nome">
              <Input value={user.usuarionome} readOnly bordered={false} />
            </Form.Item>
            <Form.Item label="Nome de Usuário">
              <Input value={user.usuariousernome} readOnly bordered={false} />
            </Form.Item>
            <Form.Item label="Email">
              <Input value={user.usuarioemail} readOnly bordered={false} />
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </ModalDisplay>
  );
};

export default ModalPerfil;
