import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer, Tooltip } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined, MenuOutlined } from '@ant-design/icons';
import card from '../../assets/login/logo-card-union.svg';
import vendaIcon from '../../assets/analytics/vendas.svg';
import pagamentoIcon from '../../assets/analytics/pagamentos.svg';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { AnalyticsIcon, AuditSalesIcon, DashboardIcon, FinancialConferenceIcon, RateIcon } from './styled';
import PortalAjuda from '../PortalAjuda';
import SubMenu from 'antd/es/menu/SubMenu';

const { Sider } = Layout;

const Sidebar: React.FC<any> = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1000);

      if (window.innerWidth > 1000 && visible) {
        setVisible(false);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [visible]);

  useEffect(() => {
    const pathname = location.pathname;
    setSelectedMenuItem(pathname);
  }, [location]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const shouldShowText = !collapsed || isMobile;

  const content = (
    <Menu
      mode="inline"
      selectedKeys={[selectedMenuItem]}
      style={{ height: '100%' }}
      onClick={isMobile ? () => setVisible(false) : undefined}>
      <img style={{ padding: 20 }} src={card} alt="Card Logo" />
      <Menu.Item title="Dashboard" key="/dashboard" icon={<DashboardIcon />}>
        <NavLink to="/dashboard">{shouldShowText && 'Dashboard'}</NavLink>
      </Menu.Item>
      <Menu.Item title="Vendas" key="/venda" icon={<AuditSalesIcon />}>
        <NavLink to="/venda">{shouldShowText && 'Vendas'}</NavLink>
      </Menu.Item>
      <SubMenu key="gestao-pagamento" icon={<FinancialConferenceIcon />} title={shouldShowText && 'Pagamentos'}>
        <Menu.Item key="/pagamento" icon={<img src={vendaIcon} alt="Pagamentos" style={{ width: 16, height: 16 }} />}>
          <NavLink to="/pagamento">Pagamentos</NavLink>
        </Menu.Item>
        <Menu.Item key="/agenda-recebimentos" icon={<img src={pagamentoIcon} alt="Recebíveis" style={{ width: 16, height: 16 }} />}>
          <NavLink to="/agenda-recebimentos">Recebíveis</NavLink>
        </Menu.Item>
        <Menu.Item key="/pagamento-consolidado" icon={<img src={pagamentoIcon} alt="Pagamento Consolidado" style={{ width: 16, height: 16 }} />}>
          <NavLink to="/pagamento-consolidado">Consolidados</NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="gestao-cliente" icon={<AnalyticsIcon />} title={shouldShowText && 'Analytics'}>
        <Menu.Item key="/analise-vendas" icon={<img src={vendaIcon} alt="Vendas" style={{ width: 16, height: 16 }} />}>
          <NavLink to="/analise-vendas">Vendas</NavLink>
        </Menu.Item>
        <Menu.Item key="/analise-pagamentos" icon={<img src={pagamentoIcon} alt="Vendas" style={{ width: 16, height: 16 }} />}>
          <NavLink to="/analise-pagamentos">Pagamentos</NavLink>
        </Menu.Item>
      </SubMenu>
      <Menu.Item title="Gestão de Taxas" key="/gestao-taxa" icon={<RateIcon />}>
        <NavLink to="/gestao-taxa">{shouldShowText && 'Taxas'}</NavLink>
      </Menu.Item>
      {!collapsed &&
        <PortalAjuda />}
    </Menu>
  );

  return (
    <>
      {isMobile && (
        <>
          <Drawer placement="left" closable={false} onClose={onClose} visible={visible} bodyStyle={{ padding: 0 }} width={isMobile ? 250 : undefined}>
            {content}
          </Drawer>
          <MenuOutlined onClick={showDrawer} style={{ padding: '10px', fontSize: '15px', lineHeight: '32px', cursor: 'pointer' }} />
        </>
      )}

      {!isMobile && (
        <Sider className={collapsed ? 'sider-collapsed' : ''} style={{ backgroundColor: 'white' }} width={250} collapsed={collapsed} onCollapse={toggleCollapsed}>
          <div style={{ backgroundColor: 'white' }}>
            {collapsed ? (
              <DoubleRightOutlined onClick={toggleCollapsed} style={{ padding: '10px', fontSize: '20px', cursor: 'pointer', color: '#252f64' }} />
            ) : (
              <DoubleLeftOutlined onClick={toggleCollapsed} style={{ padding: '10px', fontSize: '20px', cursor: 'pointer', color: '#252f64' }} />
            )}
            {content}
          </div>
        </Sider>
      )}
    </>
  );
};

export default Sidebar;
