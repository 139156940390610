export function mapFiltersVendaForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.VENDAAUTORIZACAO) {
    apiFilters.numero_autorizacao = filters.VENDAAUTORIZACAO;
    delete apiFilters.VENDAAUTORIZACAO;
  }
  if (filters.VENDANSU) {
    apiFilters.numero_nsu = filters.VENDANSU;
    delete apiFilters.VENDANSU;
  }
  if (filters.ESTABELECIMENTONUMERO) {
    apiFilters.estabelecimento_numero = filters.ESTABELECIMENTONUMERO;
    delete apiFilters.ESTABELECIMENTONUMERO;
  }
  if (filters.ADQUIRENTENOME) {
    apiFilters.adquirente_nome = filters.ADQUIRENTENOME;
    delete apiFilters.ADQUIRENTENOME;
  }
  if (filters.BANDEIRANOME) {
    apiFilters.bandeira_nome = filters.BANDEIRANOME;
    delete apiFilters.BANDEIRANOME;
  }
  if (filters.BANCONOME) {
    apiFilters.banco_nome = filters.BANCONOME;
    delete apiFilters.BANCONOME;
  }
  if (filters.MODALIDADEID) {
    apiFilters.modalidade_id = filters.MODALIDADEID;
    delete apiFilters.MODALIDADEID;
  }
  if (filters.VENDASTATUSCONCILIACAO) {
    apiFilters.status_conciliacao = filters.VENDASTATUSCONCILIACAO;
    delete apiFilters.VENDASTATUSCONCILIACAO;
  }
  if (filters.TAGTYPE) {
    apiFilters.tag_type = filters.TAGTYPE;
    delete apiFilters.TAGTYPE;
  }
  return apiFilters;
}

export function mapFiltersPagamentoForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.PAGAMENTOAUTORIZACAO) {
    apiFilters.numero_autorizacao = filters.PAGAMENTOAUTORIZACAO;
    delete apiFilters.PAGAMENTOAUTORIZACAO;
  }
  if (filters.PAGAMENTONSU) {
    apiFilters.numero_nsu = filters.PAGAMENTONSU;
    delete apiFilters.PAGAMENTONSU;
  }
  if (filters.ESTABELECIMENTONUMERO) {
    apiFilters.estabelecimento_numero = filters.ESTABELECIMENTONUMERO;
    delete apiFilters.ESTABELECIMENTONUMERO;
  }
  if (filters.ADQUIRENTENOME) {
    apiFilters.adquirente_nome = filters.ADQUIRENTENOME;
    delete apiFilters.ADQUIRENTENOME;
  }
  if (filters.BANDEIRANOME) {
    apiFilters.bandeira_nome = filters.BANDEIRANOME;
    delete apiFilters.BANDEIRANOME;
  }
  if (filters.BANCONOME) {
    apiFilters.banco_nome = filters.BANCONOME;
    delete apiFilters.BANCONOME;
  }
  if (filters.MODALIDADEID) {
    apiFilters.modalidade_id = filters.MODALIDADEID;
    delete apiFilters.MODALIDADEID;
  }
  if (filters.PAGAMENTOSTATUSCONCILIACAO) {
    apiFilters.status_conciliacao = filters.PAGAMENTOSTATUSCONCILIACAO;
    delete apiFilters.PAGAMENTOSTATUSCONCILIACAO;
  }
  if (filters.PAGAMENTOTIPOTRANSACAOID) {
    apiFilters.tipo_transacao = filters.PAGAMENTOTIPOTRANSACAOID;
    delete apiFilters.PAGAMENTOTIPOTRANSACAOID;
  }
  if (filters.TAGTYPE) {
    apiFilters.tag_type = filters.TAGTYPE;
    delete apiFilters.TAGTYPE;
  }
  return apiFilters;
}

export function mapFiltersAgendaRecebimentosForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.VENDAAUTORIZACAO) {
    apiFilters.numero_autorizacao = filters.VENDAAUTORIZACAO;
    delete apiFilters.VENDAAUTORIZACAO;
  }
  if (filters.VENDANSU) {
    apiFilters.numero_nsu = filters.VENDANSU;
    delete apiFilters.VENDANSU;
  }
  if (filters.ESTABELECIMENTONUMERO) {
    apiFilters.estabelecimento_numero = filters.ESTABELECIMENTONUMERO;
    delete apiFilters.ESTABELECIMENTONUMERO;
  }
  if (filters.ADQUIRENTENOME) {
    apiFilters.adquirente_nome = filters.ADQUIRENTENOME;
    delete apiFilters.ADQUIRENTENOME;
  }
  if (filters.BANDEIRANOME) {
    apiFilters.bandeira_nome = filters.BANDEIRANOME;
    delete apiFilters.BANDEIRANOME;
  }
  if (filters.BANCONOME) {
    apiFilters.banco_nome = filters.BANCONOME;
    delete apiFilters.BANCONOME;
  }
  if (filters.MODALIDADEID) {
    apiFilters.modalidade_id = filters.MODALIDADEID;
    delete apiFilters.MODALIDADEID;
  }
  if (filters.VENDASTATUSCONCILIACAO) {
    apiFilters.status_conciliacao = filters.VENDASTATUSCONCILIACAO;
    delete apiFilters.VENDASTATUSCONCILIACAO;
  }
  if (filters.TAGTYPE) {
    apiFilters.tag_type = filters.TAGTYPE;
    delete apiFilters.TAGTYPE;
  }
  return apiFilters;
}

export function mapFiltersPagamentosConsolidadosForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.ESTABELECIMENTONUMERO) {
    apiFilters.estabelecimento_numero = filters.ESTABELECIMENTONUMERO;
    delete apiFilters.ESTABELECIMENTONUMERO;
  }
  if (filters.ADQUIRENTENOME) {
    apiFilters.adquirente_nome = filters.ADQUIRENTENOME;
    delete apiFilters.ADQUIRENTENOME;
  }
  if (filters.BANDEIRANOME) {
    apiFilters.bandeira_nome = filters.BANDEIRANOME;
    delete apiFilters.BANDEIRANOME;
  }
  if (filters.BANCONOME) {
    apiFilters.banco_nome = filters.BANCONOME;
    delete apiFilters.BANCONOME;
  }
  if (filters.MODALIDADENOME) {
    apiFilters.modalidade_nome = filters.MODALIDADENOME;
    delete apiFilters.MODALIDADENOME;
  }
  if (filters.CONTABANCARIA) {
    apiFilters.pagamento_conta = filters.CONTABANCARIA;
    delete apiFilters.CONTABANCARIA;
  }
  if (filters.EMPRESANOME) {
    apiFilters.empresa_nome = filters.EMPRESANOME;
    delete apiFilters.EMPRESANOME;
  }
  return apiFilters;
}
